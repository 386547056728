export default {
  messages: {
    ru: {
      Показывать: 'Показывать',
      'за последние 7 дней': 'за последние 7 дней',
      'за последние 14 дней': 'за последние 14 дней',
      'за последний месяц': 'за последний месяц',
      'за последние 2 месяца': 'за последние 2 месяца',
      'за последние 3 месяца': 'за последние 3 месяца',
      'за последние 6 месяцев': 'за последние 6 месяцев',
    },
    uk: {
      Показывать: 'Показувати',
      'за последние 7 дней': 'за останні 7 днів',
      'за последние 14 дней': 'за останні 14 днів',
      'за последний месяц': 'за останній місяць',
      'за последние 2 месяца': 'за останні 2 місяці',
      'за последние 3 месяца': 'за останні 3 місяці',
      'за последние 6 месяцев': 'за останні 6 місяців',
    },
  },
};
