export default {
	props: ['pubCount', 'pubCost', 'topCount', 'advertisementId', 'levelsPopupLink'],
	i18n: {
		messages: {
			ru: {
				'Вернуться': 'Вернуться',
				'Использовано': 'Использовано',
				'Витрачено': 'Потрачено',
				'публикаций': 'публикаций',
				'Переопубликовать': 'Переопубликовать',
				'уровни': 'уровни',
				'Поднять в': 'Поднять в',
				'сумму': 'сумму',
			},
			uk: {
				'Вернуться': 'Повернутися',
				'Использовано': 'Використано',
				'Витрачено': 'Витрачено',
				'публикаций': 'публікацій',
				'Переопубликовать': 'Переопублікувати',
				'уровни': 'рівні',
				'Поднять в': 'Підняти в',
				'сумму': 'суму',
			}
		}
	},
};
