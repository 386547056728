export default {
	messages: {
		ru: {
			'Охват': 'Охват',
			'Просмотр объявления': 'Просмотр объявления',
			'Добавления в избранное': 'Добавления в избранное',
			'Сообщения в чат': 'Сообщения в чат',
			'Просмотры телефона': 'Уникальный просмотр тел.',
			'Показывать': 'Показывать',
			'за последние 7 дней': 'за последние 7 дней',
			'за последний месяц': 'за последний месяц',
			'за последние 3 месяца': 'за последние 3 месяца',
			'за последние 6 месяцев': 'за последние 6 месяцев',
		},
		uk: {
			'Охват': 'Охоплення',
			'Просмотр объявления': 'Перегляд оголошення',
			'Добавления в избранное': 'Додавання в обране',
			'Сообщения в чат': 'Повідомлення в чат',
			'Просмотры телефона': 'Унікальні перегляди тел.',
			'Показывать': 'Показувати',
			'за последние 7 дней': 'за останні 7 днів',
			'за последний месяц': 'за останній місяць',
			'за последние 3 месяца': 'за останні 3 місяці',
			'за последние 6 месяцев': 'за останні 6 місяців',
		}
	}
};
