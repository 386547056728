import {mapActions, mapGetters} from 'vuex';
import OnePublicationStat from '../../../PublicationsStatistic/OnePublicationStat/index.vue';
import Pagination from '../../../../Common/Pagination/index.vue';

export default {
	data() {
		return {
			limit: 3,
			skip: 3,
			page: 1,
			publications: []
		};
	},
	components: {
		'one-publication-stat': OnePublicationStat,
		'pagination': Pagination,
	},
	props: ['advertisementId'],
	mounted() {
		// eslint-disable-next-line max-len
		this.fetchData();
	},
	computed: {
		...mapGetters({
			publications_: 'cabinet/advertisementStat/publications',
		}),
		count() {
			return this._result(this.publications_, 'count.total');
		},
		pages() {
			return Math.ceil(this.count / this.limit);
		}
	},
	methods: {
		...mapActions({
			fetchPublications: 'cabinet/advertisementStat/publications',
		}),
		onChangePage(page) {
			this.publications = [];
			this.page = page;
			this.fetchData();
		},
		fetchData() {
			this.fetchPublications({advertisementId: this.advertisementId, limit: this.limit, skip: this.skip * this.page - this.limit}).then(() => {
				this.publications = this.publications_;
				this.$emit('publicationsCounter', this._result(this.publications_, 'count.total'));
				this.$emit('publicationsCost', this._result(this.publications_, 'count.totalCost'));
			});
		}
	},
	i18n: {
		messages: {
			ru: {
				'Использование публикаций': 'Использование публикаций',
				'У Вас нет использованых публикаций': 'У Вас нет использованых публикаций',
			},
			uk: {
				'Использование публикаций': 'Використання публікацій',
				'У Вас нет использованых публикаций': 'У Вас немає використаних публікацій',
			}
		}
	}
};
