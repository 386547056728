import Popup from '../../../../../Common/Popup.vue';

export default {
  name: 'OpenPhonesInfo',
  components: {
    Popup,
  },
  i18n: {
    messages: {
      ru: {
        'Що це і як працює': 'Что это и как работает?',
        'Серед неавторизованих користувачів': '📌 Среди неавторизованных пользователей могут быть автоматизированные системы сбора информации (другие вебсайты, автобизнесмены и т.п.), то есть представители ваших потенциальных покупателей',
        'Тепер ви можете бачити': 'Теперь вы можете видеть заинтересованность покупателя к вашему авто, а именно:',
        'перегляди авто': 'Сколько раз покупатель просмотрел авто',
        'додане в Обране': 'Добавил ли покупатель авто в избранное',
        'перегляди телефону': 'Сколько раз покупатель просмотрел телефон',
        'Як можна використовувати цю статистику:': 'Как можно использовать эту статистику:',
        'Натисніть на чат': 'Нажмите чат',
        'напишіть потенційному покупцю': 'напишите потенциальному покупателю чтобы продать авто ',
        'Запропонуйте торг': 'Предложите торг или расскажите почему именно этот автомобиль лучше других',
      },
      uk: {
        'Що це і як працює': 'Що це і як працює?',
        'Серед неавторизованих користувачів': '📌 Серед неавторизованих користувачів можуть бути автоматизовані системи збору інформації (інші вебсайти, автобізнесмени тощо), тобто представники ваших потенційних покупців',
        'Тепер ви можете бачити': 'Тепер ви можете бачити зацікавленість покупця до вашого авто, а саме:',
        'перегляди авто': 'Скільки разів покупець переглянув авто',
        'додане в Обране': 'Чи додав покупець авто в обране',
        'перегляди телефону': 'Скільки разів покупець переглянув телефон',
        'Як можна використовувати цю статистику:': 'Як можна використовувати цю статистику:',
        'Натисніть на чат': 'Натисніть на чат',
        'напишіть потенційному покупцю': 'напишіть потенційному покупцю щоб продати авто',
        'Запропонуйте торг': 'Запропонуйте торг або розкажіть чому саме це авто краще за інші',
      },
    },
  },
};
