import { mapActions, mapGetters } from 'vuex';
import PhoneOpenItem from './PhoneOpenItem/index.vue';
import OrderByPeriod from '../OrderByPeriod/index.vue';
import OpenPhonesInfoPopup from '../Popup/OpenPhonesInfo/index.vue';
import Pagination from '../../../../Common/Pagination/index.vue';

export default {
  data() {
    return {
      showBlock: false,
      showPopup: false,
      selectedPeriodId: 30,
      limit: 10,
      page: 1,
      intervals: [7, 30],
    };
  },
  components: {
    PhoneOpenItem,
    OrderByPeriod,
    Pagination,
    OpenPhonesInfoPopup,
  },
  computed: {
    ...mapGetters({
      openPhoneUsers: 'cabinet/advertisementStat/openPhoneUsers',
    }),
    count() {
      return this.openPhoneUsers?.totalCnt || 0;
    },
    pages() {
      return Math.ceil(this.count / this.limit);
    },
    showPagination() {
      return this.pages > 1;
    },
    items() {
      return this.openPhoneUsers?.data || [];
    },
    showSection() {
      return this.items?.length;
    },
  },
  methods: {
    ...mapActions({
      fetchOpenPhoneUsers: 'cabinet/advertisementStat/openPhoneUsers',
    }),
    onChangePage(page) {
      this.page = page;
      this.updateList();
    },
    onChangePeriod(period) {
      this.selectedPeriodId = period;
      this.onChangePage(1);
    },
    async updateList(additionalParams = {}) {
      return this.fetchOpenPhoneUsers({
        ...additionalParams,
        advertisementId: this.$route.params.advertisementId,
        perPage: this.limit,
        period: this.selectedPeriodId,
        page: this.page,
      });
    },
  },
  async mounted() {
    const { currentInterval, intervals } = await this.updateList({ withInterval: 1 });

    if (intervals) this.intervals = intervals;
    if (currentInterval) this.selectedPeriodId = currentInterval;
  },
  i18n: {
    messages: {
      uk: {
        'Переглядали телефон': 'Переглядали телефон',
        'Що це і як працює': 'Що це і як працює',
      },
      ru: {
        'Переглядали телефон': 'Просматривали телефон',
        'Що це і як працює': 'Что это и как работает',
      },
    },
  },
};
