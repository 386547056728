import Pagination from '../../../../Common/Pagination/index.vue';
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			limit: 10,
			page: 1,
		};
	},
	components: {
		'pagination': Pagination,
	},
	computed: {
		...mapGetters({
			charts_: 'cabinet/advertisementStat/charts',
		}),
		count() {
			return Object.keys(this.charts_).length || 0;
		},
		pages() {
			return Math.ceil(this.count / this.limit);
		},
		items() {
			return Object.entries(this.charts_)
				.sort((a, b) => new Date(b[0]) - new Date(a[0]))
				.slice((this.page - 1) * this.limit, this.page * this.limit)
				.map(entry => {
					let obj = {};
					Object.assign(obj, {date: entry[0]});
					Object.assign(obj, entry[1]);
					return obj;
				});
		},
	},
	methods: {
		onChangePage(page) {
			this.page = page;
		},
	},
	i18n: require('../Charts/i18n').default
};
