import LineChart from './../../../../../helpers/charts/line.js';
import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';

export default {
	data() {
		return {
			labelsDate: [],
			coverageData: [],
			pageData: [],
			phoneData: [],
			chatData: [],
			notepadData: [],
			activeIndex: 0,
			showPeriodSelect: false
		};
	},
	mounted() {
		this.fillData();
		this.resetChartData();
	},
	components: {
		LineChart
	},
	watch: {
		charts_() {
			this.initPeriod();
		},
	},
	computed: {
		...mapGetters({
			charts_: 'cabinet/advertisementStat/charts',
			autoData_: 'cabinet/advertisementStat/autoData',
			period: 'cabinet/advertisementStat/period',
		}),
		dataCollection() {
			return {
				datasets: [{
					label: this.$t('Охват'),
					data: this.coverageData,
					hidden: this.activeIndex !== 0,
					pointRadius: 0,
					hitRadius: 280 / this.period,
					borderWidth: 1,
					lineTension: 0,

				}, {
					label: this.$t('Просмотр объявления'),
					data: this.pageData,
					type: 'line',
					hidden: this.activeIndex !== 1,
					pointRadius: 0,
					hitRadius: 280 / this.period,
					borderWidth: 1,
					lineTension: 0,
				}, {
					label: this.$t('Добавления в избранное'),
					data: this.notepadData,
					type: 'line',
					hidden: this.activeIndex !== 2,
					pointRadius: 0,
					hitRadius: 280 / this.period,
					borderWidth: 1,
					lineTension: 0
				}, {
					label: this.$t('Сообщения в чат'),
					data: this.chatData,
					type: 'line',
					hidden: this.activeIndex !== 3,
					pointRadius: 0,
					hitRadius: 280 / this.period,
					borderWidth: 1,
					lineTension: 0
				}, {
					label: this.$t('Просмотры телефона'),
					data: this.phoneData,
					type: 'line',
					hidden: this.activeIndex !== 4,
					pointRadius: 0,
					hitRadius: 280 / this.period,
					borderWidth: 1,
					lineTension: 0
				}
				],
				labels: this.labelsDate.map((i) => i.format('DD MMM'))
			};
		},
		chartOptions() {
			return {
				legend: {
					labels: {
						boxWidth: 0,
						fontSize: 0,
						padding: 10,
						usePointStyle: false,
						filter: function (legendItem, chartData) {
							return (chartData.datasets[legendItem.datasetIndex].label);
						},
					},
					display: false,
					onClick: this.legendHandler
				},
				tooltips: {
					intersect: false,
					mode: 'index',
					filter: function (legendItem, chartData) {
						return (chartData.datasets[legendItem.datasetIndex].label);
					},
				},
				maintainAspectRatio: false
			};
		},
	},
	methods: {
		...mapActions({
			setPeriod: 'cabinet/advertisementStat/period',
		}),
		metricCount(data) {
			// eslint-disable-next-line max-len
			return (data.length && data.reduce((prev, curr) => curr ? ((prev || 0) + curr) : (prev || 0)));
		},
		changePeriod(period) {
			this.setPeriod(period);
			this.fillData();
		},
		initPeriod() {
			let advAge = this.period;
			const keys = Object.keys(this.charts_).sort();
			if (keys && keys.length) {
				advAge = moment().diff(keys[0], 'days') + 1;
			}
			this.changePeriod(advAge);
		},
		resetChartData() {
			this.showPeriodSelect = false;
			this.labelsDate = [];
			this.coverageData = [];
			this.pageData = [];
			this.phoneData = [];
			this.chatData = [];
			this.notepadData = [];
		},
		legendHandler(point, event) {
			this.activeIndex = event.datasetIndex;
		},
		fillData() {
			this.resetChartData();
			let _this = this;

			const period = {
				30: moment().diff(moment().subtract(1, 'months'), 'days'),
				90: moment().diff(moment().subtract(3, 'months'), 'days'),
				180: moment().diff(moment().subtract(6, 'months'), 'days'),
			}[this.period] || this.period;

			for (let i = 0; i <= period; i++) {
				this.labelsDate.push(moment().subtract(i, 'day'));
			}

			this.labelsDate = this.labelsDate.reverse();

			this.labelsDate.forEach((date) => {
				let chartObject = _this.charts_[date.format('YYYY-MM-DD')];
				if (chartObject) {
					this.coverageData.push(chartObject.coverage);
					this.pageData.push(chartObject.page);
					this.phoneData.push(chartObject.phone);
					this.chatData.push(chartObject.chat);
					this.notepadData.push(chartObject.notepad);
				} else {
					this.coverageData.push(undefined);
					this.pageData.push(undefined);
					this.phoneData.push(undefined);
					this.chatData.push(undefined);
					this.notepadData.push(undefined);
				}
			});
		}
	},
	i18n: require('./i18n').default,
};
