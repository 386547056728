import {mapGetters} from 'vuex';

export default {
	data: function () {
		return {
			action: undefined
		};
	},
	props: ['data'],
	i18n: {
		messages: {
			uk: {
				'Начисление': 'Нарахування',
				'Расход': 'Витрата',
				'Начислено Администратором': 'Нараховано Адміністратором',
				'Снято Администратором': 'Знято Адміністратором',
				'Удаление обьявления модератором. Возврат публикаций.': 'Видалення оголошення модератором. Повернення публікацій.',
				'Начислено в подарок бесплатные публикации': 'Нараховано в подарунок безкоштовні публікації',
				'Возврат публикаций за объявление': 'Повернення публікацій за оголошення',
				'день': '0 днів | {count} день | {count} дні | {count} днів',
				'месяц': 'місяців | місяць | місяці | місяців',
				'Публикация объявления': 'Публікація оголошення',
				'на период': 'на період',
				'Повторная публикация объявления': 'Повторна публікація оголошення',
				'Автоматическая повторная публикация объявления': 'Автоматична повторна публікація оголошення',
				'Снятие публикации Администратором с объявления': 'Зняття публікації Адміністратором з оголошення',
				'Публикация с мобильного приложения': 'Публікація з мобільного додатка',
				'Обратите внимание! Вашa публикация была использована на другом аккаунте для публикации объявления:': 'Зверніть увагу! Вашa публікація була використана на іншому акаунті для публікації оголошення:',
				'Подтвердите телефон': 'Підтвердіть телефон',
				'чтоб закрепить его за текущей учетной записью и исключить возможность мошенничества': 'щоб закріпити його за поточним обліковим записом і виключити можливість шахрайства',
				'Остаток': 'Залишок',
				'Время': 'Час',
				'Нараховано за умовами пакета': 'Нараховано за умовами пакета',
				'Списання публікацій у зв\'язку із закриттям пакету': 'Списання публікацій у зв\'язку із закриттям пакету',
				'Преміум': 'Преміум'
			},
			ru: {
				'Начисление': 'Начисление',
				'Расход': 'Расход',
				'Начислено Администратором': 'Начислено Администратором',
				'Снято Администратором': 'Снято Администратором',
				'Удаление обьявления модератором. Возврат публикаций.': 'Удаление обьявления модератором. Возврат публикаций.',
				'Начислено в подарок бесплатные публикации': 'Начислено в подарок бесплатные публикации',
				'Возврат публикаций за объявление': 'Возврат публикаций за объявление',
				'месяц': 'месяцев | месяц | месяца | месяцев',
				'день': '0 дней | {count} день | {count} дня | {count} дней',
				'Публикация объявления': 'Публикация объявления',
				'на период': 'на период',
				'Повторная публикация объявления': 'Повторная публикация объявления',
				'Автоматическая повторная публикация объявления': 'Автоматическая повторная публикация объявления',
				'Снятие публикации Администратором с объявления': 'Снятие публикации Администратором с объявления',
				'Публикация с мобильного приложения': 'Публикация с мобильного приложения',
				'Обратите внимание! Вашa публикация была использована на другом аккаунте для публикации объявления:': 'Обратите внимание! Вашa публикация была использована на другом аккаунте для публикации объявления:',
				'Подтвердите телефон': 'Подтвердите телефон',
				'чтоб закрепить его за текущей учетной записью и исключить возможность мошенничества': 'чтоб закрепить его за текущей учетной записью и исключить возможность мошенничества',
				'Остаток': 'Остаток',
				'Время': 'Время',
				'Нараховано за умовами пакета': 'Начислено по условиям пакета',
				'Списання публікацій у зв\'язку із закриттям пакету': 'Списание публикаций в связи с закрытием пакета',
				'Преміум': 'Премиум'
			}
		}
	}
};
