import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
	extends: Line,
	mixins: [reactiveProp],
	props: ['options', 'chartData'],
	mounted() {
		// this.chartData создаётся внутри миксина.
		// Если вы хотите передать опции, создайте локальный объект options
		this.renderChart(this.chartData, this.options);
	}
};
