import Pagination from '../../../../Common/Pagination/index.vue';
import {mapActions, mapGetters} from 'vuex';

export default {
	data() {
		return {
			limit: 3,
			skip: 3,
			page: 1,
			levels: [],
			legend: [],
			loading: false
		};
	},
	components: {
		'pagination': Pagination,
	},
	props: ['advertisementId'],
	mounted() {
		// eslint-disable-next-line max-len
		this.fetchData();
	},
	computed: {
		...mapGetters({
			levels_: 'cabinet/advertisementStat/top',
		}),
		count() {
			return this._result(this.levels_, 'count');
		},
		pages() {
			return Math.ceil(this.count / this.limit);
		},
	},
	methods: {
		...mapActions({
			fetchLevels: 'cabinet/advertisementStat/top',
		}),
		onChangePage(page) {
			this.loading = true;
			this.page = page;
			this.fetchData();
		},
		fetchData() {
			this.fetchLevels({advertisementId: this.advertisementId, limit: this.limit, skip: this.skip * this.page - this.limit}).then(() => {
				this.levels = this.levels_.log;
				this.legend = this.levels_.legend;
				this.loading = false;
				this.$emit('levelsCounter', this.levels_.spentMoneyCount);
			});
		}
	},
	i18n: {
		messages: {
			ru: {
				'Использование уровней': 'Использование уровней',
				'Уровень': 'Уровень',
				'Дата активации': 'Дата активации',
				'Дата окончания': 'Дата окончания',
				'Период': 'Период',
				'день': '0 дней | {count} день | {count} дня | {count} дней',
				'У вас немає використаних рівнів ТОП': 'У вас нет использованных уровней ТОП',
			},
			uk: {
				'Использование уровней': 'Використання рівнів',
				'Уровень': 'Рівень',
				'Дата активации': 'Дата активації',
				'Дата окончания': 'Дата закінчення',
				'Период': 'Період',
				'день': '0 днів | {count} день | {count} дні | {count} днів',
				'У вас немає використаних рівнів ТОП': 'У вас немає використаних рівнів ТОП',
			}
		}
	}
};
