import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      pubCount: undefined,
      pubCost: undefined,
      topCount: undefined,
      chartToggle: 'chart',
    };
  },
  // eslint-disable-next-line no-dupe-keys
  components: {
    'vue-charts': require('./Charts/index.vue').default,
    'vue-stat-publications': require('./Publications/index.vue').default,
    'vue-stat-levels': require('./Levels/index.vue').default,
    'call-to-action': require('./CallToActionBlock/index.vue').default,
    'efficiency-table': require('./EfficiencyTable/index.vue').default,
    'phone-open-history': require('./PhoneOpenHistory/index.vue').default,
  },
  mounted() {
    this.fetchAutoData(this.$route.params.advertisementId).catch(console.error);
    this.fetchCharts(this.$route.params.advertisementId).catch(console.error);
  },
  computed: {
    ...mapGetters({
      autoData_: 'cabinet/advertisementStat/autoData',
      charts_: 'cabinet/advertisementStat/charts',
      period: 'cabinet/advertisementStat/period',
      notYourCar_: 'cabinet/advertisementStat/notYourCar',
    }),
    autoData() {
      return this.autoData_;
    },
    advertisementId() {
      return this.$route.params.advertisementId;
    },

  },
  methods: {
    ...mapActions({
      fetchAutoData: 'cabinet/advertisementStat/autoData',
      fetchCharts: 'cabinet/advertisementStat/charts',
    }),
    setPublicationsCounter(count) {
      this.pubCount = count;
    },
    setPublicationsCost(count) {
      this.pubCost = count;
    },
    setLevelsCounter(count) {
      this.topCount = count;
    },
  },
  i18n: {
    messages: {
      ru: {
        'Статистика объявления': 'Статистика объявления',
        Вернуться: 'Вернуться',
        Использовано: 'Использовано',
        публикаций: 'публикаций',
        Переопубликовать: 'Переопубликовать',
        уровней: 'уровней',
        'Поднять в': 'Поднять в',
        'Перевірте посилання!': 'Проверьте ссылку!',
        'Вказане ID належить не вашій пропозиції. Статистика авто доступна тільки їх продавцям.': 'Указанное ID принадлежит не вашему объявлению. Статистика авто доступна только их продавцам.',
        Эффективность: 'Эффективность',
        'Эффективность вашего объявления': 'Эффективность вашего объявления',
        График: 'График',
        Таблица: 'Таблица',
        'за последние 7 дней': 'за последние 7 дней',
        'за последний месяц': 'за последний месяц',
        'за последние 3 месяца': 'за последние 3 месяца',
        'за последние 6 месяцев': 'за последние 6 месяцев',
      },
      uk: {
        'Статистика объявления': 'Статистика оголошення',
        Вернуться: 'Повернутися',
        Использовано: 'Використано',
        публикаций: 'публікацій',
        Переопубликовать: 'Переопублікувати',
        уровней: 'рівнів',
        'Поднять в': 'Підняти в',
        'Перевірте посилання!': 'Перевірте посилання!',
        'Вказане ID належить не вашій пропозиції. Статистика авто доступна тільки їх продавцям.': 'Вказане ID належить не вашій пропозиції. Статистика авто доступна тільки їх продавцям.',
        Эффективность: 'Ефективність',
        'Эффективность вашего объявления': 'Ефективність вашого оголошення',
        График: 'Графік',
        Таблица: 'Таблиця',
        'за последние 7 дней': 'за останні 7 днів',
        'за последний месяц': 'за останній місяць',
        'за последние 3 месяца': 'за останні 3 місяці',
        'за последние 6 месяцев': 'за останні 6 місяців',
      },
    },
  },
};
