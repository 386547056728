import i18n from './i18n';

export default {
  name: 'OrderByPeriod',
  props: {
    jsTarget: String,
    availablePeriodIds: {
      type: Array,
      default: () => ([7, 30, 90, 180]),
    },
    periodId: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      showPeriodSelect: false,
    };
  },
  computed: {
    periods() {
      return [
        {
          id: 7,
          text: this.$t('за последние 7 дней'),
        },
        {
          id: 14,
          text: this.$t('за последние 14 дней'),
        },
        {
          id: 30,
          text: this.$t('за последний месяц'),
        },
        {
          id: 60,
          text: this.$t('за последние 2 месяца'),
        },
        {
          id: 90,
          text: this.$t('за последние 3 месяца'),
        },
        {
          id: 180,
          text: this.$t('за последние 6 месяцев'),
        },
      ];
    },
    activePeriod() {
      return this.periods?.find((item) => item.id === this.periodId);
    },
    show() {
      return this.activePeriod?.id;
    },
  },
  methods: {
    changePeriod(period) {
      this.showPeriodSelect = false;
      this.$emit('change', period);
    },
  },
  i18n,
};
